import http from "./http";
import XEUtils from "xe-utils";
export const getToken = function (file) {
    return http.get('/portal/index/getFileToken', {
        params: {
            type: file.type,
            size: file.size,
            name: file.name
        }
    })
}

function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
}

/**
 * 将blob转换为file
 * 临时文件地址转file
 * @param theBlob
 * @param fileName
 * @returns {*}
 */
function blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
}

/**
 * bs64转file
 * @param b64
 * @param name
 * @returns {*}
 */
export function b64HuanFile(b64, name) {
    var blob = dataURLtoBlob(b64);
    return blobToFile(blob, name);
}


/**
 * 获取文件类型，文件后缀
 * @param file
 * @returns {string}
 */
export function getFileType(file) {
    let index = XEUtils.toString(file).lastIndexOf('.')
    //获取后缀
    return  XEUtils.toString(file).substr(index + 1).split('!')[0].toLowerCase()
}

/**
 * 图片后缀
 * @returns {string[]}
 */
export const getImageTypeList = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'bmp', 'gif', 'pic', 'tif'] //图片
export const getYaSuoBaoType = ['zip', 'rar', 'arj', 'gz'] //压缩包
export const getBiaoGeType = ['xls', 'xlsx', 'csv'] //表格
export const getWordType = ['word', 'doc', 'wps','docx'] //文档
export const getPdfType = ['pdf'] //pdf

export function getShowUrl(file) {

    let type = getFileType(file)
    if (getImageTypeList.indexOf(type.toLowerCase()) !== -1) {
        return file
    }
    else if (type == 'pdf') {
        return require('@/assets/icon/pdf.png')
    }
    else if (getYaSuoBaoType.includes(type)) {
        return require('@/assets/icon/yan_suo_bao_icon.png')
    }
    else if (getWordType.includes(type)) {
        return require('@/assets/icon/docx.png')
    }
    else  if(getBiaoGeType.includes(type)) {
        return require('@/assets/icon/xlsb_icon.png')
    }
    else {
        return require('@/assets/icon/file_icon.png')
    }
}

